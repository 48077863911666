:root {
  --rmdp-primary-green: #95a5a6;
  --rmdp-secondary-green: #95a5a6;
  --rmdp-shadow-green: #95a5a6;
  --rmdp-today-green: #95a5a6;
  --rmdp-hover-green: #95a5a6;
  --rmdp-deselect-green: #95a5a6;
}

.green .rmdp-wrapper {
  border: 1px solid var(--rmdp-secondary-green);
  box-shadow: 0 0 5px var(--rmdp-secondary-green);
}

.green .rmdp-panel-body li {
  background-color: var(--rmdp-primary-green);
  box-shadow: 0 0 2px var(--rmdp-secondary-green);
}

.green .rmdp-week-day {
  color: var(--rmdp-primary-green);
}

.green .rmdp-day.rmdp-deactive {
  color: var(--rmdp-secondary-green);
}

.green .rmdp-range {
  background-color: var(--rmdp-primary-green);
  box-shadow: 0 0 3px var(--rmdp-shadow-green);
}

.green .rmdp-arrow {
  border: solid var(--rmdp-primary-green);
  border-width: 0 2px 2px 0;
}

.green .rmdp-arrow-container:hover {
  background-color: var(--rmdp-primary-green);
  box-shadow: 0 0 3px var(--rmdp-secondary-green);
}

.green .rmdp-panel-body::-webkit-scrollbar-thumb {
  background: var(--rmdp-primary-green);
}

.green .rmdp-day.rmdp-today span {
  background-color: var(--rmdp-today-green);
}

.green .rmdp-rtl .rmdp-panel {
  border-left: unset;
  border-right: 1px solid var(--rmdp-secondary-green);
}

.green .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--rmdp-primary-green);
  box-shadow: 0 0 3px var(--rmdp-shadow-green);
}

.green .rmdp-day:not(.rmdp-day-hidden) span:hover {
  background-color: var(--rmdp-hover-green) !important;
}

.green .b-deselect {
  color: var(--rmdp-deselect-green);
  background-color: white;
}

.green .rmdp-action-button {
  color: var(--rmdp-primary-green);
}

.green .rmdp-button:not(.rmdp-action-button) {
  background-color: var(--rmdp-primary-green);
}

.green .rmdp-button:not(.rmdp-action-button):hover {
  background-color: var(--rmdp-deselect-green);
}

.my-component .lines {
  width: 575px;
  margin-top: 15px;
}
.my-component .pie {
  width: 420px;
}

.fullscreen-enabled .my-component {
  background: #fff;
  height: 100vh;
}
.fullscreen-enabled .my-component .icon {
  display: none;
}
.fullscreen-enabled .my-component .lines {
  width: 75%;
}
.fullscreen-enabled .my-component .pie {
  width: 55%;
}

@media print {
  @page {
    size: landscape;
    height: 80%;
  }
}
