/* html {
  background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%);
  overflow: hidden;
}

body {
  background: transparent;
  color: #ffffff;
} */

.well {
  padding: 2px;
  font-family: cursive;
}

.bluelight {
  color: blue; /* Ensure this color is visible against the background */
  font-weight: bold; /* Optional: make it stand out more */
  text-shadow: 0 0 10px #3498db, 0 0 20px #3498db, 0 0 30px #3498db, 0 0 40px #2980b9, 0 0 70px #2980b9,
    0 0 80px #2980b9, 0 0 100px #2980b9, 0 0 150px #2980b9;
}

.clock {
  overflow: hidden;
}

.col-md-12 {
  overflow: hidden;
  padding-bottom: 20px;
}

.top-pane-1 {
  margin-bottom: 0px;
  border-radius: 5px 5px 0px 0px;
  background-color: #FEFCFF;
  border: 0px;
  padding: 3px;
  border: 1px solid #FB5D2E;
}

.bottom-pane-1 {
  margin-top: 0px;
  border-radius: 0px 0px 5px 5px;
  background-color: #FB5D2E;
  border: 0px;
  padding: 5px;
  color: #ffffff;
}

@media (min-width: 768px) {
  .num {
    font-size: 15px;
     display: flex; /* Updated from JavaScript-like syntax */
    align-items: center; /* Updated from JavaScript-like syntax */
    justify-content: center; /* Updated from JavaScript-like syntax */
    height: 100%; /* Updated from JavaScript-like syntax */
    color: #212668;
  }
}

@media (max-width: 767px) {
  .num {
    font-size: 15px;
     display: flex; /* Updated from JavaScript-like syntax */
    align-items: center; /* Updated from JavaScript-like syntax */
    justify-content: center; /* Updated from JavaScript-like syntax */
    height: 100%; /* Updated from JavaScript-like syntax */
    color: #212668;
  }
}

@media (max-width: 480px) {
  .num {
    font-size: 15px;
     display: flex; /* Updated from JavaScript-like syntax */
    align-items: center; /* Updated from JavaScript-like syntax */
    justify-content: center; /* Updated from JavaScript-like syntax */
    height: 100%; /* Updated from JavaScript-like syntax */
    color: #212668;
  }
}

@media (min-width: 768px) {
  .text {
    font-size: 10px;
  }
}

@media (max-width: 767px) {
  .text {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  .text {
    font-size: 10px;
  }
}

/* @media (min-width: 768px) {
  body {
    padding-top: 75px;
  }
} */

h1 {
  padding-top: 10px;
}

@media (min-width: 768px) {
  h1 {
    margin-bottom: 75px;
    font-size: 10px;
  }
}

.text{
   display: flex; /* Updated from JavaScript-like syntax */
    align-items: center; /* Updated from JavaScript-like syntax */
    justify-content: center; /* Updated from JavaScript-like syntax */
    height: 100%; /* Updated from JavaScript-like syntax */
}