Holder styles
.holder {
    position: absolute;
  /* background-color: #ffffff; */
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
}

/* New Year container styles */
.new-year {
  font-family: 'Major Mono Display', monospace;
  color: #333333;
  font-size: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
}

/* Year styles */
.year {
  position: relative;
  left: 10%;
  display: table;
  font-size: 3em;
  transform: translateX(-50%);
}

.year2 {
  transform: translateX(-50%) rotateX(180deg);
  opacity: 0.2;
}

/* Digit styles */
.digit {
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative;
  opacity: 0;
}

/* Controls container styles */
.controls {
  margin-top: 30px;
}

.control {
   color: #FFFFFF; /* Ensure text color is visible */
  font-size: 2rem; /* Adjust font size as needed */
  margin: 0 10px; /* Add spacing between controls */
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 0.8em;
  border-left: 1px solid #333333;
  opacity: 0;
  position: relative;
  top: 100px;
}

.control:first-child {
  border-left: none;
}

/* Triangle styles */
.triangles {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #333333;
  position: absolute;
  opacity: 0;
}
.new-year-animation {
  position: absolute; /* Adjust as necessary */
  top: 10%; /* Change this value to adjust vertical position */
  left: 10%; /* Change this value to adjust horizontal position */
  /* Add any other styles you need */
}